@mixin desktop-xlarge-only {
  @media (min-width: #{$grid-desktop-xlarge}) {
    @content; } }
@mixin desktop-xlarge {
  @media (max-width: #{$grid-desktop-xlarge}) {
    @content; } }
@mixin desktop-only {
  @media (min-width: #{$grid-xl}) {
    @content; } }
@mixin desktop-large {
  @media (max-width: #{$grid-desktop-large}) {
    @content; } }

@mixin desktop {
  @media (max-width: #{$grid-xl}) {
    @content; } }

@mixin desktop-true {
  @media (max-width: #{$grid-desktop-true}) {
    @content; } }

@mixin laptop {
  @media (max-width: #{$grid-lg}) {
    @content; } }

@mixin tablet {
  @media (max-width: #{$grid-md}) {
    @content; } }

@mixin mobile {
  @media (max-width: #{$grid-sm}) {
    @content; } }
