section#contact {
	padding-top: 60px;
	padding-bottom: 0px;
	.description {
		text-align: center;
		font-size: 16px;
		color: #292929;
		max-width: 100%;
		line-height: 26px;
		span {
			font-weight: 600; } }
	.map {
		overflow: hidden;
		width: 100%;
		clear: both;
		pointer-events: none;
		padding-left: 80px;
		@include desktop-xlarge {
			padding-left: 40px; }
		@include desktop-large {
			padding-left: 0px; }
		position: relative;
		#map-canvas {
			width: 100%;
			height: 640px; }
		.map-wrapper {
		  padding-top: 30px;
		  a {
		    display: block; } } }
	.inner {
		padding-top: 30px; }
	.contact-form {
		max-width: 670px;
		@include desktop-xlarge {
			max-width: 550px; }
		margin: 0 auto;
		padding: 50px 0;
		.textarea {
			margin: 30px 0; }
		@include desktop {
			padding: 50px; } } }
@include mobile {
	section#contact {
		padding-top: 30px;
		.description {
			text-align: center;
			font-size: 13px;
			line-height: 18px;
			div {
				padding: 5px 0; } }
		.map {
			#map-canvas {
				width: 100%;
				height: 440px; } }
		.inner {
			padding-top: 15px; }
		.contact-form {
			padding: 25px; } } }
