body.home {
    header {
      .logo {
         a {
            background: url(../img/partials/header/logo.svg) no-repeat center; } } } }
body.home {
    header.sticky {
      .logo {
         a {
            background: url(../img/partials/header/logo-alt.svg) no-repeat center; } } } }
#scroll-bar {
	right: 0px;
	top: 30vh;
	margin-top: -50px;
	z-index: 999;
	position: fixed;
	@include desktop {
		display: none; }
	ul {
		list-style: none;
		margin: 0;
		padding: 0; }
	li {
		position: relative;
		background-color: $site-color2;
		border-radius: 15px 15px 15px 15px;
		cursor: pointer;
		padding: 8px;
		height: 15px;
		margin: 60px 30px;
		width: 15px;
		display: block;
		vertical-align: bottom;
		&:before {
			content: "";
			position: absolute;
			z-index: -1;
			top: -50px;
			bottom: -50px;
			left: 50%;
			border-left: 1px solid #244f82; } }
	li.active, li:hover {
		background-color: $site-color3; }
	a {
		outline: 0;
		vertical-align: top;
		margin: 0px;
		position: relative;
		padding: 15px 25px;
		top: 0;
		left: 0; } }

#scroll-bar li.default {
	background-color: $site-color7; }
#scroll-bar li.default.active, #scroll-bar li.default:hover {
	background-color: $site-color3; }
#scroll-bar li.default {
	&:before {
		border-left: 1px solid #244f82; } }
#scroll-bar li.color-scheme1 {
	background-color: $site-color2; }
#scroll-bar li.color-scheme1.active, #scroll-bar li.color-scheme1:hover {
	background-color: $site-color3; }
#scroll-bar li.color-scheme1 {
	&:before {
		border-left: 1px solid #d0d5dc; } }
#scroll-bar li.color-scheme2 {
	background-color: $site-color2; }
#scroll-bar li.color-scheme2.active, #scroll-bar li.color-scheme2:hover {
	background-color: $site-color7; }
#scroll-bar li.color-scheme2 {
	&:before {
		border-left: 1px solid #e0e5ec; } }

.awesome-tooltip + .tooltip > .tooltip-inner {
    background-color: $site-color3;
    color: #f5f5f5;
    border: 1px solid $site-color3; }
.awesome-tooltip + .tooltip.left > .tooltip-arrow {
    top: 50%;
    right: 0;
    margin-top: -5px;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
    border-left: 5px solid $site-color3; }

section#home-slider {
	padding: 0; }
#home-carousel {
	.controls {
		border-radius: 35px;
		border: solid 1px #fff;
		height: 60px;
		position: absolute;
		right: 15%;
		bottom: 165px;
		width: 132px;
		@include desktop {
			margin: 0 auto;
			right: 0;
			left: 0;
			bottom: 40px; }
		.left {
			width: 65px;
			height: 60px;
			display: inline-block;
			line-height: 60px;
			color: $site-color7;
			font-size: 35px;
			font-weight: lighter;
			border-right: 1px solid $site-color7;
			float: left; }
		.left:hover {
			background: $site-color7;
			color: $site-color8;
			border-radius: 35px 0px 0px 35px; }
		.right {
			width: 65px;
			height: 60px;
			line-height: 60px;
			color: $site-color7;
			font-size: 35px;
			font-weight: lighter;
			display: inline-block;
			float: right; }
		.right:hover {
			background: $site-color7;
			color: $site-color8;
			border-radius: 0px 35px 35px 0px; }
		@include mobile {
			height: 42px;
			width: 92px;
			bottom: 60px;
			.left {
				width: 45px;
				height: 40px;
				line-height: 40px; }
			.right {
				width: 45px;
				height: 40px;
				line-height: 40px; } } }
	.slick-dots {
		bottom: 100px;
		text-align: center;
		position: absolute;
		right: 0;
		left: 0;
		bottom: 70px;
		margin: 0 auto;
		width: 20px;
		z-index: 999;
		text-align: center;
		@include desktop {
			display: none!important; }
		li {
			height: 10px;
			display: inline-block;
			margin: 0px;
			button {
				display: block;
				height: 5px;
				margin: 5px auto;
				width: 5px;
				padding: 2px;
				border: none;
				background: #fff;
				color: #fff;
				z-index: 999; }
			button:before {
				line-height: 16px;
				color: #f1c809; } }
		li.slick-active {
			button:before {
				height: 10px;
				line-height: 16px;
				color: #f1c809; } } }
	.slick-dotted.slick-slider {
		padding: 0;
		margin: 0; }
	.carousel-wrapper {
		display: none;
		position: absolute;
		left: 0;
		right: 0;
		top: 290px;
		margin-left: auto;
		margin-right: auto;
		z-index: 2;
		text-align: center;
		.caption {
			width: 950px;
			height: 192px;
			color: #fff;
			text-align: center;
			font-size: 55px;
			line-height: 65px;
			font-weight: bold;
			margin: 25px auto;
			overflow: hidden; }
		button, .button {
			top: 650px;
			z-index: 2;
			font-size: 17px;
			text-align: center;
			width: 235px;
			height: 60px;
			border-radius: 35px;
			border: solid 1px #fff;
			line-height: 55px;
			background: none;
			margin: auto;
			margin-top: 60px;
			margin-bottom: 120px;
			color: $site-color7;
			a:link, a:visited {
				color: $site-color7; }
			a:hover, a:focus {
				color: $site-color3; } }
		button:hover, .button:hover {
			background: $site-color7;
			color: $site-color2;
			a:link, a:visited {
				color: $site-color2; } }
		@include desktop {
			top: 250px;
			.caption {
				width: 90%;
				font-size: 40px;
				line-height: 50px;
				margin: 40px auto; }
			button, .button {
				margin-top: 10px;
				margin-bottom: 120px; }
			.vertical-line1 {
				height: 50px;
				display: inline-block;
				width: 2px;
				overflow: hidden; } }
		@include mobile {
			.caption {
				height: 60px;
				font-size: 24px;
				line-height: 30px;
				margin: 20px auto; }
			button, .button {
				z-index: 2;
				font-size: 12px;
				text-align: center;
				width: 185px;
				height: 40px;
				line-height: 40px;
				background: none;
				margin: auto;
				margin-top: 20px;
				margin-bottom: 80px; } } } }
#about {
	padding: 65px 0;
	@include mobile {
		padding: 35px 0; }
	.teasers {
		padding: 20px 0;
		min-height: 540px;
		@include desktop-large {
			min-height: 0px; }
		@include desktop {
			padding: 0; }
		.teaser {
			width: 460px;
			margin-right: 40px;
			h3 {
				padding-bottom: 135px; }
			@include mobile {
				h3 {
					font-size: 17px;
					padding-bottom: 0px;
					position: absolute;
					width: 100%; } }
			.text {
				min-height: 385px;
				border: solid 1px #e1e1e1;
				padding-top: 160px;
				padding-left: 45px;
				padding-right: 45px;
				padding-bottom: 65px;
				font-size: 16px;
				font-weight: lighter;
				color: #535151; }
			.teaser-image {
				position: absolute;
				left: 45px;
				top: 65px; }
			@include mobile {
				.teaser-image {
					top: 50px;
					position: relative; } }
			img.teaser-image {
			  -webkit-filter: grayscale(0);
			  filter: none; }
			img.teaser-image:hover {
			  filter: gray;
			  -webkit-filter: grayscale(1);
			  filter: grayscale(1); }
			.read-more {
				position: absolute;
				bottom: 0;
				margin: 0 auto;
				left: 0;
				right: 0;
				width: 50%;
				font-size: 14px;
				font-weight: bold;
				display: inline-block;
				padding: 15px; }
			.read-more.sliding-inverse:after {
				background: #4cb7ef; }
			.read-more.sliding-inverse:hover:before {
				background: #4cb7ef; }
			@include desktop-xlarge {
				width: 30%;
				margin-right: 3%;
				.read-more {
					position: absolute;
					display: block;
					left: 0;
					right: 0;
					width: 40%; }
				.text {
					padding-top: 135px; }
				.teaser-image {
					left: 5%; } }
			@include laptop {
				width: auto;
				float: none;
				max-width: 535px;
				margin: 10px auto;
				padding: 10px 0;
				.text {
					padding-top: 135px;
					padding-left: 45px;
					padding-right: 45px;
					padding-bottom: 85px;
					margin: 25px;
					min-height: 0px; }
				.teaser-image {
					left: 0;
					right: 0;
					padding-left: 25px;
					padding-right: 25px; }
				img.teaser-image {
				  filter: none;
				  -webkit-filter: none;
				  filter: none; }
				img.teaser-image:hover {
				  -webkit-filter: none;
				  filter: none; }
				.read-more {
					width: 25%;
					bottom: 35px; } }
			@include mobile {
				.teaser-image {
					padding-left: 50px;
					padding-right: 50px; }
				.text {
					font-size: 13px;
					line-height: 18px;
					padding-left: 25px;
					padding-right: 25px;
					padding-top: 50px;
					padding-bottom: 50px;
					font-weight: 300; }
				.read-more {
					width: 50%;
					font-size: 12px;
					bottom: 35px; } } }
		.teaser.last {
			margin: 0;
			@include desktop {
				margin: 5px auto; } } } }
#insurance {
	padding: 0;
	.teasers {
		background: #ededed;
		padding: 60px 40px;
		margin: 40px 0;
		min-height: 810px;
		@include desktop-large {
			min-height: 0px; }
		@include mobile {
			padding: 20px 0; }
		.ic-logo {
			margin: 50% 0;
			width: 185px; }
		.teaser {
			margin: 40px 0;
			@include desktop-true {
				margin: 0px auto;
				padding: 40px;
				max-width: auto; }
			h3 {
				font-size: 24px;
				a:link, a:visited {
					color: #303030; }
				a:hover, a:focus {
					color: $site-color2; } }
			.text {
				padding: 5px 0;
				font-size: 16px;
				min-height: 185px; }
			.read-more {
			    display: inline-block;
			    padding: 10px;
			    font-size: 14px; }
			a:link.read-more, a:visited.read-more {
				color: 	#303030; }
			a:hover.read-more, a:focus.read-more {
				color: 	$site-color2; }
			.read-more.red.sliding-inverse:after {
				background: #e8685f; }
			.read-more.red.sliding-inverse:hover:before {
				background: #e8685f; }
			.read-more.blue.sliding-inverse:after {
				background: #2ab2e3; }
			.read-more.blue.sliding-inverse:hover:before {
				background: #2ab2e3; }
			.read-more.green.sliding-inverse:after {
				background: #26d5b6; }
			.read-more.green.sliding-inverse:hover:before {
				background: #26d5b6; }
			.read-more.yellow.sliding-inverse:after {
				background: #f1af0f; }
			.read-more.yellow.sliding-inverse:hover:before {
				background: #f1af0f; }
			@include desktop {
				.text {
					min-height: initial; } }
			@include mobile {
				padding: 20px;
				h3 {
					font-size: 15px; }
				.text {
					font-size: 13px;
					line-height: 18px;
					min-height: initial;
					p {
						margin-bottom: 0px; } }
				.read-more {
					font-size: 11px;
					padding-top: 0px; } } } } }
#services {
	padding: 0;
	.overlay {
		position: absolute;
		top: 0px;
		right: 0px;
		left: 0px;
		bottom: 0px;
		height: 100%;
		width: 100%;
		background-color: rgba(4, 166, 226, 0.85);
		z-index: 0; }
	.teasers {
		margin: 20px 0;
		@include desktop-large;
		.buttons {
			padding: 0;
			max-width: 650px;
			margin: 0 auto;
			display: table;
			border-collapse: separate;
			border-spacing: 20px;
			.button {
				overflow: hidden;
				padding: 0;
				text-align: center;
				width: 260px;
				height: 75px;
				display: table;
				line-height: 16px;
				font-size: 12px;
				a {
					display: table-cell;
					text-align: center;
					vertical-align: middle; }
				span {
					padding-left: 10px;
					font-size: 12px; } } }
		.service1 {
			padding: 100px 0;
			min-height: 750px;
			h3 {
				font-size: 45px;
				color: #fff;
				padding: 10px 0;
				font-weight: bold;
				position: relative;
				z-index: 1; }
			.description {
			    padding: 15px;
			    color: #fff;
			    font-weight: 300;
			    max-width: 500px;
			    position: relative;
			    z-index: 1;
			    p {
			    	min-height: 42px; } }
			@include desktop {
				min-height: auto;
				padding: 50px 0;
				h3 {
					font-size: 34px; } } }
		.service2 {
			.overlay {
				background-color: rgba(229, 229, 229, 0.85); }
			padding: 100px 0;
			min-height: 750px;
			h3 {
				font-size: 45px;
				color: #012b63;
				padding: 10px 0;
				font-weight: bold;
				position: relative;
				z-index: 1; }
			.description {
			    padding: 15px;
			    color: #012b63;
			    font-weight: 300;
			    max-width: 500px;
			    position: relative;
			    z-index: 1;
			    p {
			    	min-height: 42px; } }
			.button {
				border: solid 1px $site-color2;
				color: $site-color2;
				a:link, a:visited {
					color: $site-color2; } }
			.button:hover, .button:hover {
				color: $site-color7;
				background: $site-color2;
				a:link, a:visited {
					color: $site-color7; } }
			@include desktop {
				min-height: auto;
				padding: 50px 0;
				p {
					min-height: 40px; }
				h3 {
					font-size: 34px; } } } } }
@include desktop {
	#services {
		.teasers {
			.buttons {
				padding: 0px;
				&:before {
					display: inline; }
				&:after {
					display: inline; } } } } }
@include mobile {
	#services {
		.teasers {
			.buttons {
				padding: 0px;
				&:before {
					display: inline; }
				&:after {
					display: inline; }
				.button {
					font-size: 12px;
					line-height: 12px;
					text-align: center;
					height: 40px;
					display: table;
					span {
						padding-left: 10px;
						font-size: 10px; } } }
			.service1 {
				min-height: auto;
				padding: 50px 0;
				h3 {
					font-size: 17px;
					color: #fff;
					padding: 5px 0;
					font-weight: bold; }
				.button {
					font-size: 9px;
					line-height: 17px;
					text-align: center;
					height: 40px; } }
			.service2 {
				min-height: auto;
				padding: 50px 0;
				h3 {
					font-size: 17px;
					padding: 5px 0; }
				.button {
					font-size: 9px;
					line-height: 17px;
					text-align: center;
					height: 40px; } } } } }
#news {
	padding: 60px 0;
	.teasers {
		padding: 40px 0;
		margin-left: 100px;
		@include desktop-large {
			margin: 0 auto;
			padding: 40px; }
		@include laptop {
			padding: 0px 40px; }
		.teaser {
			.teaser-image {
				text-align: left;
				img:hover {
				  filter: gray;
				  -webkit-filter: grayscale(1);
				  filter: grayscale(1); }
				img {
				  -webkit-filter: grayscale(0);
				  filter: none; }
				@include desktop {
					text-align: center;
					max-width: 280px;
					margin: 0 auto; } }
			padding: 30px 0;
			h3 {
				font-size: 22px;
				min-height: 80px; }
			.text {
				text-align: left;
				padding-top: 15px;
				padding-bottom: 15px;
				padding-left: 25px;
				padding-right: 50px;
				height: 400px;
				overflow: hidden;
				line-height: 20px;
				.info {
					font-size: 13px;
					color: #e1e1e1;
					span {
						font-size: 14px;
						color: #bcbcbc; } }
				.cnt {
					min-height: 160px; } }
			@include desktop {
				h3 {
					font-size: 22px;
					min-height: initial; }
				.text {
					text-align: center;
					min-height: 320px;
					height: auto;
					line-height: 22px;
					font-size: 18px;
					padding-right: 25px;
					max-width: 425px;
					margin: 0 auto;
					.cnt {
						min-height: initial; } } }
			.read-more {
				display: inline-block;
				padding: 15px; }
			.read-more.sliding-inverse:after {
				background: #4cb7ef;
				height: 2px; }
			.read-more.sliding-inverse:hover:before {
				background: #4cb7ef;
				height: 2px; } } } }
@include mobile {
	#news {
		padding: 15px 0;
		.teasers {
			text-align: center;
			padding: 20px 0;
			.teaser {
				padding: 15px 0;
				h3 {
					font-size: 15px; }
				.text {
					min-height: initial;
					text-align: center;
					padding-top: 15px;
					padding-bottom: 15px;
					padding-left: 15px;
					padding-right: 15px;
					line-height: 18px;
					font-size: 13px;
					.info {
						font-size: 11px;
						color: #dddddd;
						span {
							font-size: 12px; } } } } } } }
