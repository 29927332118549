section#theysaid {
	.overlay {
		background-color: rgba(4,166,226,0.95);
		position: absolute;
		top: 0px;
		right: 0px;
		left: 0px;
		bottom: 0px;
		height: 100%;
		width: 100%; }
	z-index: 0;
	padding: 170px 0;
	color: $site-color7;
	@include desktop {
		padding: 65px 0; }
	h2 {
		font-size: 50px;
		color: $site-color7;
		font-weight: 100;
		padding: 10px 0;
		position: relative;
		z-index: 1; }
	.description {
		font-size: 16px;
		color: $site-color7;
		font-weight: 100;
		padding: 25px;
		position: relative;
		z-index: 1; }
	.carousel-wrapper {
		.quotel {
			background: url(../img/quotel.png) no-repeat center;
			width: 60px;
			height: 40px;
			left: 145px;
			top: -20px;
			z-index: 1;
			position: absolute;
			display: inline-block;
			@include laptop {
				left: 4%; } }
		.quoter {
			background: url(../img/quoter.png) no-repeat center;
			width: 60px;
			height: 40px;
			right: 145px;
			bottom: -20px;
			z-index: 1;
			position: absolute;
			display: inline-block;
			@include laptop {
				right: 4%; } }
		.carousel-inner {
			background: rgba(255, 255, 255, 0.2);
			font-size: 20px;
			font-weight: lighter;
			text-align: center;
			line-height: 24px;
			margin: 0 auto;
			width: 700px;
			min-height: 230px;
			@include laptop {
				width: auto; }
			.item {
				width: 100%!important;
				height: 100%!important;
				top: 0;
				position: absolute;
				padding: 0 50px;
				div {
					display: flex!important;
					justify-content: center!important;
					align-content: center!important;
					flex-direction: row!important;
					height: 100%!important;
					width: 100%!important;
					p {
						align-self: center!important;
						text-align: center; } } } }
		.carousel-indicators {
			bottom: -40px; }
		.carousel-indicators li {
			border: none;
			width: 10px;
			height: 10px;
			margin: 0 8px;
			background: $site-color7; }
		.carousel-indicators li.active {
			background: $site-color2;
			width: 10px;
			height: 10px; }
		@include desktop {
			.carousel-indicators li {
				width: 14px;
				height: 14px; }
			.carousel-indicators li.active {
				width: 14px;
				height: 14px; } } }
	.teaser {
		@include desktop {
			padding: 15px 0px; } } }
@include mobile {
	section#theysaid {
		padding: 35px 0;
		h2 {
			font-size: 25px;
			padding: 10px 0; }
		.description {
			font-size: 13px;
			padding-left: 25px;
			line-height: 18px; }
		.carousel-wrapper {
			.quotel {
				background: url(../img/quotel.png) no-repeat center;
				width: 60px;
				height: 40px;
				left: 4%;
				top: -20px;
				z-index: 1;
				position: absolute;
				display: inline-block; }
			.quoter {
				background: url(../img/quoter.png) no-repeat center;
				width: 60px;
				height: 40px;
				right: 4%;
				bottom: -20px;
				z-index: 1;
				position: absolute;
				display: inline-block; }
			.carousel-inner {
				font-size: 13px;
				line-height: 18px;
				margin: 0 auto;
				width: auto;
				.item {
					padding: 25px 50px; } } } } }
