/*****Footer Styling********/
footer {
	width: 100%;
	position:relative {}
	.top {
		.icba {
			img {
				padding-top: 15px;
				position: absolute; }
			h3 {
				padding-left: 90px; } }
		padding: 60px 0;
		h3 {
			font-size: 22px;
			font-weight: bold;
			color: #0c131f;
			@include desktop {
				font-size: 24px;
				color: $site-color2; } }
		.links {
			padding: 5px 0; }
		.button2 {
			margin: 0; }
		input[type="submit"].form-control {
			position: absolute;
			right: 0px;
			top: -10px;
			width: 60px;
			background: none;
			color: #30b9ba;
			cursor: pointer;
			border: none; }
		input[type="submit"].form-control:hover + span {
			color: $site-color2; }
		.newsletter-form span.glyphicon {
			position: absolute;
			right: 13px;
			top: 18px;
			z-index: 2;
			color: #30b9ba;
			background: #fff;
			pointer-events: none; }
		.newsletter-form span.glyphicon:hover {
			color: $site-color2; }
		.newsletter-form .form-group {
			width: 340px;
			position: relative; }
		@include desktop {
			padding: 0;
			text-align: center;
			.button2 {
				margin: 0 auto;
				font-size: 16px; }
			.newsletter-form .form-group {
				width: 100%;
				position: relative;
				margin: 0 auto; }
			.icba {
				img {
					padding-top: 15px;
					position: relative; }
				h3 {
					padding: 15px 0; }
				padding: 40px;
				border-top: none;
				border-bottom: none; }
			.newsletter {
				padding: 40px;
				border-top: none;
				border-bottom: none; }
			.social {
				padding: 40px;
				border-top: none;
				border-bottom: none; } }
		@include laptop {
			.newsletter-form .form-group {
				width: 50%; }
			.icba {
				border-top: solid 1px #ebebeb;
				border-bottom: solid 1px #ebebeb; }
			.newsletter {
				padding: 40px;
				border-top: solid 1px #ebebeb;
				border-bottom: solid 1px #ebebeb; }
			.social {
				padding: 40px;
				border-top: solid 1px #ebebeb;
				border-bottom: solid 1px #ebebeb; } } }
	.description {
		font-size: 16px;
		line-height: 24px;
		margin: 25px 0;
		padding-right: 100px;
		color: #0c131f;
		span {
			font-weight: bold; }
		@include desktop {
			font-size: 20px;
			padding-right: 0px; } }
	.middle {
		font-family: $font-family1;
		font-size: 16px;
		font-weight: 300;
		background: url(../img/partials/footer/middle-bg.png) repeat center;
		color: $site-color7;
		.navigation {
			text-align: center;
			li {
				padding: 30px 50px;
				border-right: 2px solid #3c5c87;
				display: inline-block; }
			li a {
				color: $site-color7; }
			li a:hover, li a:focus {
				color: $site-color3; } }
		.navigation ul li.last {
			border:none!important {} } }
	.bottom {
		padding: 24px 0;
		color: $site-color4;
		font-family: $font-family1;
		font-size: 13px;
		@include desktop {
			font-size: 20px;
			padding: 35px 0;
			text-align: center; }
		.copyright {
			padding: 5px 0; }
		.powered {
			text-align: right;
			padding: 5px 0;
			@include desktop {
				text-align: center; }
			color: $site-color6;
			img {
				margin-top: -3px;
				margin-left: 8px; } } } }
@include mobile {
	footer {
		.top {
			padding: 0;
			text-align: center;
			h3 {
				font-size: 15px;
				padding: 5px 0; }
			.button2 {
				margin: 0 auto;
				font-size: 15px; }
			.newsletter-form .form-group {
				width: 275px;
				position: relative;
				margin: 0 auto; }
			.icba {
				padding: 20px;
				img {
					padding-top: 10px;
					position: relative; }
				h3 {
					font-size: 15px;
					padding: 10px 0; }
				.links {
					font-size: 13px; } }
			.newsletter {
				padding: 20px; }
			.social {
				padding: 20px; } }
		.description {
			font-size: 13px;
			line-height: 18px;
			padding: 0;
			margin: 15px 0; }
		.bottom {
			padding: 15px 0;
			line-height: 16px;
			.powered {
				font-size: 11px; }
			.copyright {
				font-size: 12px; } } } }
