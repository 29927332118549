/*****Form Elements********/
.form-control::-webkit-input-placeholder {
	color: #b8beca;
	font-size: 14px; }
.form-control::-moz-placeholder {
	color: #b8beca;
	font-size: 14px; }

.form-control:-ms-input-placeholder {
	color: #b8beca;
	font-size: 14px; }

.form-control:-moz-placeholder {
	color: #b8beca;
	font-size: 14px; }

input.form-control::-moz-focus-inner {
	border: 0; }

input.form-control:focus {
	outline-width: 0;
	outline: none;
	border-bottom: $site-color2 solid 1px;
	-webkit-box-shadow: none;
	box-shadow: none; }
textarea.form-control:focus {
	border: $site-color2 solid 1px;
	-webkit-box-shadow: none;
	box-shadow: none;
	outline-width: 0;
	outline: none; }

input.form-control.nostyle:focus {
	outline-width: 5px; }

.form-control {
    -webkit-box-shadow: none;
    box-shadow: none; }


label {
	font-weight: 100;
	display: block;
	width: 100%; }

.newsletter-form input.form-control {
	border: $site-color8 solid 1px;
	border-radius: 20px;
	margin: 10px 0; }

input.form-control {
	height: 50px;
	margin: 15px 0;
	font-weight: normal;
	padding-left: 16px;
	color: #b8beca;
	border: none;
	border-radius: 0;
	border-bottom: $site-color2 solid 1px;
	appearance: none;
	-moz-appearance: none;
	-webkit-appearance: none;
	border-radius: 0;
	-moz-border-radius: 0;
	-webkit-border-radius: 0; }

input[type="submit"].form-control {
	color: $site-color7;
	font-weight: 400;
	border: none;
	width: 170px;
	font-size: 16px;
	border-bottom: solid 2px;
	color: $site-color3;
	border-radius: 0;
	float: right;
	@include desktop {
		float: none;
		margin: 0 auto; }
	@include mobile {
		font-size: 13px; } }
input[type="submit"].form-control:hover {
	color: $site-color2; }

textarea.form-control {
	color: #b8beca;
	width: 100%;
	height: 150px;
	display: inline-block;
	padding: 15px;
	font-weight: normal;
	margin: 30px 0;
	border-radius: 0;
	color: #b8becay;
	border: $site-color2 solid 1px;
	margin: 0 auto;
	@include mobile {
		font-size: 13px; } }

