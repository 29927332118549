@import 'variables';
@import 'reset';
@import 'mixin';

@import url("../../dev/third-party/bootstrap/dist/css/bootstrap.min.css");
@import url("../../dev/third-party/font-awesome/css/font-awesome.min.css");
@import url("../../dev/third-party/animate.css/animate.min.css");
@import url("../../dev/third-party/slick-carousel/slick/slick.css");
@import url("../../dev/third-party/slick-carousel/slick/slick-theme.css");

/*****General Styling********/
@keyframes imageAnimation {
  0% {
    transform: scale(1);
    animation-timing-function: ease-in; }

  25% {
    transform: scale(1.1);
    animation-timing-function: ease-out; }

  50% {
    animation-timing-function: ease-in;
    transform: scale(1.2); }

  75% {
    animation-timing-function: ease-in;
    transform: scale(1.1); }

  100% {
    animation-timing-function: ease-in;
    transform: scale(1); } }

*, *:before, *:after {
	box-sizing: border-box; }

body, html {
	font-family: $font-family1;
	font-weight: 300;
	font-size: 16px;
	line-height: 1.6; }
body {
	line-height: 22px;
	-webkit-text-size-adjust: none;
	-webkit-appearance: none;
	-moz-appearance: none;
	overflow-x: hidden;
	position: relative;
	margin: 0 auto;
	color: $site-color1;
	background: $site-color7;
	overflow-x: hidden;
	position: relative;
	@include mobile {
		font-size: 13px;
		line-height: 18px; } }

.content-width-outer {
	margin: 0 auto;
	max-width: $content-width-outer;
	position: relative; }

.content-width {
	margin: 0 auto;
	max-width: $content-width;
	position: relative; }

.content-width-inner {
	margin: 0 auto;
	max-width: $content-width-inner;
	position: relative; }

.content-width-inner2 {
	margin: 0 auto;
	max-width: $content-width-inner2;
	position: relative; }

.content-padding {
	padding: 0px 40px;
	@include desktop {
		padding: 0px 25px; }
	@include laptop {
		padding: 0px 25px; }
	@include mobile {
		padding: 0px 15px; } }
.center-align {
	text-align: center; }
strong {
	font-weight: bold!important; }
section {
	padding: 50px 0;
	text-align: center;
	position: relative;
	a {
		text-decoration: none;
		color: $hover-color;
		outline: 0; }

	a:link, a:visited {
		text-decoration: none;
		color: $hover-color;
		outline: 0; }

	a:hover, a:focus {
		text-decoration: none;
		color: $links-color;
		outline: 0; }
	.description {
		padding: 50px 0;
		display: inline-block;
		max-width: 640px;
		text-align: center;
		font-size: 16px;
		color: #969696;
		@include mobile {
			padding: 25px 0;
			font-size: 13px;
			line-height: 18px; } } }
section.error {
	padding-top: 600px;
	padding-bottom: 100px;
	h3 {
	  color: $site-color1;
	  font-weight: 300; }
	@include desktop-large {
      padding-top: 50px;
      padding-bottom: 50px; }
	@include mobile {
	  padding-top: 0px;
	  padding-bottom: 0px; } }
section.thankyou {
	padding-top: 550px;
	padding-bottom: 80px;
	text-align: left;
	.row {
		padding-top: 100px; }
	.text {
		padding: 35px;
		line-height: 30px; }
	.read-more {
		padding-top: 35px;
		float: left; }
	@include desktop-large {
		padding-top: 50px;
		padding-bottom: 50px;
		.row {
			padding-top: 50px; } }
	@include mobile {
		.row {
			padding-top: 25px; }
		text-align: center;
		.text {
			padding: 35px;
			line-height: 20px; }
		.read-more {
			padding-top: 35px;
			float: none; }
		padding-top: 0px;
		padding-bottom: 0px; } }
h1 {
	position: relative;
	font-family: $font-family1;
	color: $site-color2;
	font-size: 55px;
	font-weight: bold;
	z-index: 1;
	overflow: hidden;
	text-align: left;
	display: inline-block;
	padding: 15px 0;
	@include desktop {
		font-size: 30px;
		padding: 5px 0; }
	@include mobile {
		font-size: 24px;
		padding: 5px 0; } }

h2 {
	position: relative;
	font-family: $font-family1;
	color: $site-color2;
	font-size: 55px;
	font-weight: bold;
	padding: 15px 0;
	@include desktop {
		font-size: 30px;
		padding: 5px 0; }
	@include mobile {
		font-size: 24px;
		padding: 5px 0; } }

h3 {
	color: #029de0;
	font-family: $font-family1;
	font-size: 26px;
	padding: 15px 0;
	font-weight: 600;
	@include mobile {
		font-size: 24px; }
	span {
		color: $site-color2;
		font-size: 17px;
		padding: 15px 0;
		font-weight: 500;
		display: block; } }
h4 {
	color: $site-color7;
	font-size: 17px;
	padding: 5px 0;
	span {
		color: $site-color3; } }
strong {
	font-weight: bold;
	span {
		color: $site-color2; } }
hr {
	display: block;
	height: 1px;
	border: 0;
	margin: 0;
	padding: 0;
	border-top: solid 1px rgba(235, 235, 235, 1); }
ul {
	padding: 0;
	display: inline; }

li, ol, dl {
	padding: 5px 0;
	list-style-type: none;
	display: inline; }

li.last ,ol.last, dl.last {
	padding: 0px; }

a {
	text-decoration: none;
	color: $links-color;
	outline: 0; }

a:link, a:visited {
	text-decoration: none;
	color: $links-color;
	outline: 0; }
a:hover, a:focus {
	text-decoration: none;
	color: $hover-color;
	outline: 0; }

a img:hover, a img:focus {
	opacity: 0.85; }
p {
	margin: 0 0 10px; }
/*****Elements********/
button, .button {
	font-size: 14px;
	text-align: center;
	width: 240px;
	height: 60px;
	border-radius: 35px;
	border: solid 1px $site-color7;
	line-height: 55px;
	background: none;
	margin: 15px auto;
	color: $site-color7;
	a, a:link, a:visited {
		color: $site-color7;
		display: inline-block;
		width: 100%;
		height: 100%; }
	a:hover, a:focus {
		color: $site-color3; } }
button:hover, .button:hover {
	background: $site-color7;
	color: $site-color2;
	a:link, a:visited {
		color: $site-color2; }
	a:hover, a:focus {
		color: $site-color2; } }
.sliding-inverse {
	display: inline-block;
	position: relative;
	padding-bottom: 2px; }
.sliding-inverse:before {
	content: '';
	display: block;
	position: absolute;
	left: 0;
	bottom: 0;
	height: 4px;
	width: 100%;
	transition: width 0s ease; }
.sliding-inverse:after {
	content: '';
	display: block;
	position: absolute;
	right: 0;
	bottom: 0;
	height: 4px;
	width: 100%;
	background: $site-color3;
	transition: width .5s ease; }
.sliding-inverse:hover:before {
	width: 0%;
	background: $site-color3;
	transition: width .5s ease; }
.sliding-inverse:hover:after {
	width: 0%;
	background: transparent;
	transition: width 0s ease; }

.read-more {
	margin: 0 auto;
	display: inline-block;
	padding: 15px; }

.button2 {
	color: $site-color7;
	font-weight: 400;
	border: none;
	font-size: 16px;
	color: $site-color3;
	border-radius: 0;
	margin: 10px 0;
	padding: 15px;
	text-align: center; }
.button2:hover {
	color: $site-color3;
	a:hover, a:focus {
			color: $site-color3; } }
.button2.sliding-inverse:after {
	background: $site-color3;
	height: 2px; }
.button2.sliding-inverse:hover:before {
	background: $site-color3;
	height: 2px; }

.vertical-line2 {
	@include desktop {
		display: none; } }
.cyrcle {
	background: url(../img/cyrcle.png) no-repeat center;
	padding: 40px 0;
	text-align: center;
	@include mobile {
	    padding: 25px 0;
	    background-size: 55px; } }
.cyrcle2 {
	background: url(../img/cyrcle2.png) no-repeat center;
	padding: 80px 0;
	font-size: 40px;
	text-align: center;
	@include mobile {
		font-size: 20px {
	    background-size: 55px; } } }
.cyrcle3 {
	background: url(../img/cyrcle3.png) no-repeat center;
	padding: 40px 0;
	font-size: 60px;
	color: #fff;
	text-align: center;
	@include mobile {
		padding: 20px 0;
		background-size: 55px; } }
.tel-mob {
	display: none;
	background: url(../img/icons/phone.svg) no-repeat center;
	padding: 0;
	a {
		display: inline-block;
		width: 50px;
		height: 50px;
		padding: 0 25px; } }
.fb {
	background: url(../img/icons/facebook.svg) no-repeat center;
	padding: 0px 15px;
	display: inline-block;
	a {
		display: inline-block;
		width: 25px;
		height: 50px;
		padding: 0 15px; }
	@include mobile {
		background-size: 15px; } }
.twitter {
	background: url(../img/icons/twitter.svg) no-repeat center;
	padding: 0px 15px;
	display: inline-block;
	a {
		display: inline-block;
		width: 25px;
		height: 50px;
		padding: 0 15px; }
	@include mobile {
		background-size: 35px; } }
.blogline {
	background: url(../img/blogline.png) no-repeat center;
	display: block;
	position: absolute;
	left: 0;
	width: 5px;
	top: 60px;
	height: 100px; }
.blogline2 {
	background: url(../img/blogline2.png) no-repeat center;
	display: block;
	position: absolute;
	top: 0;
	left: 0;
	right: 0;
	width: 100%;
	height: 5px; }
.parallax-window {
	min-height: 530px;
	background: transparent;
	@include mobile {
		min-height: 430px; } }
.parallax-window-slider {
	min-height: 880px;
	@include desktop {
		min-height: 800px; }
	@include mobile {
		min-height: 600px; } }

.share-fb {
	background: url(../img/icons/facebook.svg) no-repeat center;
	display: inline-block; }
.share-twitter {
	background: url(../img/icons/twitter.svg) no-repeat center;
	display: inline-block; }
.share-linkedin {
	background: url(../img/icons/linkiedin.png) no-repeat center;
	display: inline-block; }
.share-googleplus {
	background: url(../img/icons/googleplus.svg) no-repeat center;
	display: inline-block; }

.two-columns {
	columns: 2;
	column-gap: 60px; }

@media screen and (max-width: 1200px) {
	.two-columns {
		columns: 1; } }
#subscribe_message {
	&.error {
		color: #a94442;
		font-size: 85%; }
	&.sucess {
		color: green; } }
input.form-control:focus, .has-error .form-control {
	box-shadow: none !important; }
.has-error .form-control-feedback {
	display: flex;
	align-items: center;
	justify-content: center; }
.help-block {
	text-align: left; }

@import 'forms';
@import 'partials/header';
@import 'partials/footer';
@import 'partials/section-contact';
@import 'partials/section-theysaid';
@import 'home';
@import 'blog';
@import 'general';
@import 'contact';


.tooltip {
  font-size: 12px; }
.tooltip.left {
  margin-left: -10px; }
.tooltip-inner {
  max-width: 85px;
  padding: 5px 10px; }
