/*****Header Styling********/
header {
	width: 100%;
	font-family: $font-family1;
	font-weight: 300;
	color: $site-color7;
	padding-left: 6%;
	padding-right: 5%;
	padding-top: 40px;
	padding-bottom: 40px;
	z-index: 999;
	position: absolute;
	background-repeat: repeat;
	background-position: center center;
	background-size: cover;
	&.none {
		background-color: $site-color2;
		background-image: none !important; }
	.overlay {
		position: absolute;
		top: 0px;
		right: 0px;
		left: 0px;
		bottom: 0px;
		display: none; }
	@include desktop-xlarge {
		padding-left: 3%;
		padding-right: 3%; }
	@include desktop {
		padding: 0; }
	.logo {
		a {
			width: 400px;
			height: 75px;
			display: block;
			margin: 0 auto;
			background: url(../img/partials/header/logo-alt.svg) no-repeat center;
			background-size: 400px!important; }
		a:hover, a:focus {
			opacity: 0.8; }
		@include desktop-xlarge {
			a {
				width: 340px;
				background-size: 340px!important; } }
		@include desktop-large {
			a {
				width: 300px;
				background-size: 300px!important; } }
		@include desktop {
			width: 100%;
			padding-top: 125px; }
		@include desktop {
			a {
				background-size: 260px!important; } }
		@include mobile {
			a {
				background-size: 175px!important; }
			padding-top: 80px; } }
	.submenu-toggle {
		position: absolute;
		right: 50px;
		top: 35px;
		@include mobile {
			top: 15px; }
		right: 0px;
		font-size: 20px;
		font-weight: lighter;
		cursor: pointer;
		width: 60px;
		height: 40px;
		text-align: center;
		display: none; }
	.logo-mobile {
		display: none;
		@include desktop {
			a {
				width: 260px;
				height: 75px;
				display: block;
				margin: 0 auto;
				background: url(../img/partials/header/logo.svg) no-repeat center;
				background-size: 260px!important;
				position: absolute;
				margin: 0 auto;
				right: 0;
				left: 0;
				top: 10px; } }
		@include mobile {
			a {
				width: 150px;
				height: 75px;
				display: block;
				margin: 0 auto;
				background: url(../img/partials/header/logo-mobile.svg) no-repeat center;
				background-size: 150px!important;
				position: absolute;
				margin: 0 auto;
				right: 0;
				left: -15px;
				top: -5px; } } }
	.dropdown-open {
		cursor: pointer;
		width: 50px;
		height: 50px;
		display: inline-block;
		position: absolute;
		left: 50px;
		top: 25px;
		z-index: 1;
		background: url(../img/icons/menu.svg) no-repeat center;
		display: none;
		@include desktop {
			display: block;
			box-shadow: none; }
		@include mobile {
			top: 7px;
			left: 15px;
			background-size: 40px; } }
	.dropdown-close {
		cursor: pointer;
		width: 75px;
		height: 75px;
		display: inline-block;
		position: absolute;
		right: 10%;
		top: 25px;
		z-index: 1;
		background: url(../img/icons/close.svg) no-repeat center;
		display: none;
		@include desktop {
			display: block; }
		@include mobile {
			background-size: 55px;
			right: 8%; } }
	.navigation {
		z-index: 999;
		padding: 15px 0;
		@include desktop-large {
		    padding: 0; }
		@include desktop {
			position: fixed;
			width: 100%;
			background: #fff;
			top: 0;
			height: 90px;
			border-bottom: solid 1px rgba(235, 235, 235, 0.5); }
		@include mobile {
			padding: 0;
			height: 65px; }
		.menu {
			.info {
				display: none; }
			visibility: visible;
			text-align: right;
			li {
				> .submenu-right {
					display: none; }
				display: inline-block;
				padding-left: 5%;
				position: relative;
				@include desktop-xlarge {
					padding-left: 3%;
					font-size: 15px; }
				a {
					padding: 15px 0;
					display: block;
					border-top: 2px solid rgba(73, 185, 233, 0);
					text-shadow: 1px 1px #012b63; }
				a.active, a:active {
					border-top: 2px solid #49b9e9; } }
			ul.submenu {
				li {
					display: flex;
					align-items: center; }
				li a {
					border: none;
					text-shadow: none;
					font-size: 14px;
					line-height: 18px; }
				li:hover, li:focus {
					ul.submenu {
						display:none {} } } }
			li a:link, li a:visited {
				color: $site-color7; }
			li a:hover, li a:focus {
				color: $site-color3;
				border-top: 2px solid #49b9e9; }
			li:hover, li:focus {
				ul.submenu {
					opacity: 1;
					visibility: visible;
					display: block;
					-webkit-transform: translateY(0px);
					-moz-transform: translateY(0px);
					transform: translateY(0px);
					-webkit-transition: all 0.5s ease-in-out;
					-moz-transition: all 0.5s ease-in-out;
					transition: all 0.5s ease-in-out;
					box-shadow: 10px 15px 17px -9px rgba(0, 0, 0, 0.10);
					@include desktop-only {
						li {
							.submenu-right {
								display: block;
								content: "\f105";
								font-size: 20px;
								font-family: 'FontAwesome';
								display: inline-block;
								position: absolute;
								right: 15px;
								color: black;
								font-size: 16px; }
							ul.submenu {
								display: none;
								box-shadow: none; } }
						li:hover, li:focus {
							ul.submenu {
								box-shadow: none;
								display: block;
								li {
									ul.submenu {
										display: none; } }
								li:hover, li:focus {
									ul.submenu {
										display: block;
										li {
											ul.submenu {
												display: none; } }
										li:hover, li:focus {
											ul.submenu {
												display: block; } } } } } } } } }
			@include desktop {
				ul.submenu {
					box-shadow: none!important; }
				.submenu-right {
					display: none!important; }
				z-index: 999;
				.info {
					display: block;
					h3 {
						color: $site-color7;
						font-size: 24px;
						padding: 20px 0; }
					text-align: left;
					div {
						padding: 20px 40px; } }
				position: absolute;
				text-align: right;
				z-index: -1;
				left: -550px;
				opacity: 0;
				top: 0;
				width: auto;
				margin-left: 0;
				overflow: auto;
				height: 100vh;
				max-width: 550px;
				background: rgb(1,43,99);
				background: -moz-linear-gradient(-45deg,  rgba(1,43,99,1) 0%, rgba(67,126,224,1) 100%);
				background: -webkit-linear-gradient(-45deg,  rgba(1,43,99,1) 0%,rgba(67,126,224,1) 100%);
				background: linear-gradient(135deg,  rgba(1,43,99,1) 0%,rgba(67,126,224,1) 100%);
				filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#012b63', endColorstr='#437ee0',GradientType=1 );
				-webkit-transition: all 0.5s ease-in-out;
				-moz-transition: all 0.5s ease-in-out;
				-o-transition: all 0.5s ease-in-out;
				transition: all 0.5s ease-in-out;
				ul {
					display: block;
					margin-right: 10%;
					margin-top: 120px;
					box-shadow: none;
					li {
						display: block;
						text-align: left;
						border-bottom: solid 1px rgba(89, 133, 201, 0.5);
						padding-left: 45px;
						padding-right: 0;
						font-weight: bold;
						box-shadow: none;
						a {
							padding: 25px 0;
							display: block;
							border: none;
							width: 90%;
							background: none!important; }
						a.active, a:active {
							border: none; }
						a:hover, a:focus {
							border: none; } }
					li.last {
						border-bottom: none; }
					li:hover, li:focus {
						.submenu.active {
							display: block; }
						.submenu {
							display: none; } } }
				.submenu-toggle {
					display: block;
					z-index: 9; }
				.submenu {
					.submenu-toggle {
						top: 15px; }
					display: none;
					position: relative;
					left: 0px;
					width: 100%;
					padding: 0;
					margin: 0;
					text-align: center;
					background: none;
					visibility: visible;
					opacity: 1;
					-webkit-transform: translateY(0px);
					-moz-transform: translateY(0px);
					transform: translateY(0px);
					-webkit-transition: none;
					-moz-transition: none;
					-o-transition: none;
					transition: none;
					box-shadow: none;
					li {
						border: none;
						margin: 0;
						padding: 0;
						width: 100%;
						display: block;
						text-align: left;
						a {
							padding: 10px 0;
							text-align: left;
							margin: 0;
							width: 90%; } }
					li:hover, li:focus {
						background: none;
						box-shadow: none; }
					a {
						padding: 15px 0;
						display: block;
						z-index: 999;
						border: none; }
					a.active, a:active {
						border-top: none;
						background: none; }
					li a:link, li a:visited {
						color: $site-color7; }
					li a:hover, li a:focus {
						color: $site-color3;
						border-top: none;
						background: none; } }
				.submenu.active {
					display: block;
					padding-left: 45px;
					li {
						font-weight: normal;
						min-height: 45px;
						display: block; } } }

			@include mobile {
				.info {
					display: block;
					h3 {
						font-size: 15px;
						padding: 10px 0; }
					text-align: left;
					div {
						padding: 15px 25px;
						font-size: 13px; } }
				ul {
					margin-top: 100px;
					li {
						font-size: 12px;
						padding-left: 25px;
						a {
							padding: 10px 0; } }
					li.last {
						border-bottom: none; }
					li:hover, li:focus {
						.submenu.active {
							display: block;
							box-shadow: none; }
						.submenu {
							display: none; } }
					.submenu.active {
						display: block;
						box-shadow: none;
						padding-left: 15px; } } } }

		.submenu {
			position: absolute;
			left: calc(55% - 100%);
			right: calc(45% - 100%);
			width: 250px;
			padding: 0;
			margin: 0 auto;
			text-align: center;
			background: $site-color7;
			visibility: hidden;
			opacity: 0;
			z-index: 3;
			-webkit-transform: translateY(0px);
			-moz-transform: translateY(0px);
			transform: translateY(0px);
			-webkit-transition: all 0.5s ease-in-out;
			-moz-transition: all 0.5s ease-in-out;
			transition: all 0.5s ease-in-out;
			li {
				margin: 0;
				padding: 0;
				width: 100%;
				color: #46b3e4;
				text-align: left;
				padding-left: 10px;
				padding-right: 0px;
				min-height: 65px;
				border-top: solid 1px #dce2ea;
				.submenu {
					display: none; } }
			li:hover, li:focus {
				background: #f5f7f9;
				@include desktop-only {
					.submenu {
						left: 250px;
						right: 0;
						top: -1px;
						display: block; } } }
			a {
				padding: 15px 0;
				display: block;
				z-index: 999; }
			a.active, a:active {
				border-top: none; }
			li a:link, li a:visited {
				color: $site-color2; }
			li a:hover, li a:focus {
				color: $site-color3;
				border-top: none;
				background: url(../img/bullet1.png) no-repeat left center; }
			li a {
				display: inline-block;
				background: url(../img/bullet2.png) no-repeat left center;
				padding: 10px;
				margin: 5px;
				text-align: center;
				width: 100%;
				padding-left: 10%;
				padding-right: 10%; } }
		.lang-switcher {
			text-align: right;
			padding: 15px 0;
			.tel {
				font-weight: bold;
				font-size: 20px;
				display: inline-block;
				padding-right: 35px;
				@include desktop-xlarge {
					padding-right: 4%;
					font-size: 18px; }
				a:link, a:visited {
					color: $site-color7; }
				a:hover, a:focus {
					color: $site-color3; } }
			li {
				a:link, a:visited {
					color: $site-color2; }
				a:hover, a:focus {
					 background: $site-color3;
					 color: $site-color7; }
				a {
				    display: inline-block;
				    background: #fff;
				    padding: 5px;
				    width: 35px;
				    border-radius: 35px;
				    font-size: 14px;
				    text-align: center; } }
			@include desktop {
				float: right;
				padding: 0;
				right: 25px;
				top: 10px;
				.tel-mob {
					display: inline-block;
					&:before {
						content: "-";
						font-size: 35px;
						line-height: 65px;
						color: $site-color2; } }
				.tel {
					display: none; }
				li {
					.submenu {
						display: none;
						li {
							min-height: 50px;
							a {
								background: none!important; } } } }
				li:hover, li:focus {
					.submenu {
						left: 0;
						right: 0;
						top: 0px;
						display: none; } }
				ul li {
					a:link, a:visited {
						color: $site-color2; }
					a:hover, a:focus {
						 background: none;
						 color: $site-color2; }
					a {
						border-radius: 0;
						padding: 0;
						margin: 0;
						width: 55px;
						font-size: 22px;
						font-weight: bold;
						text-align: center;
						vertical-align: super;
						background: none!important; } }
				ul li:first-child {
					&:before {
						content: "-";
						font-size: 35px;
						line-height: 60px;
						color: $site-color2; } } }
			@include mobile {
				right: 15px;
				top: 5px;
				.tel-mob {
					background: url(../img/icons/phone.svg) no-repeat 15px 15px;
					&:before {
						content: none; }
					background-size: 16px;
					a {
						width: 25px;
						height: 25px;
						padding: 20px; } }
				ul li {
					a {
						font-size: 16px;
						width: 40px; } }
				ul li:first-child {
					&:before {
						content: none; } } } } } }
.header-title {
	display: none; }
header.default, header.none {
	background: $site-color2;
	position: fixed;
	@include desktop-xlarge {
		position: relative; }
	@include desktop {
		height: 240px; }
	@include mobile {
		height: 190px;
		.logo {
			padding-top: 95px; } } }
header.background {
	background: $site-color2;
	height: 485px;
	position: fixed;
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	-o-transition: all 0.5s ease;
	transition: all 0.5s ease;
	.header-title {
		position: relative;
		width: 100%;
		left: 0px;
		right: 0px;
		top: 100px;
		text-align: center;
		display: block; }
	@include desktop-xlarge {
		position: relative; }
	@include desktop {
		.header-title {
			h1 {
				padding-left: 25px;
				padding-right: 25px;
				font-size: 50px; }
			top: 50px; } }
	@include mobile {
		height: 380px;
		.header-title {
			h1 {
				text-align: center;
				padding-left: 10px;
				padding-right: 10px;
				font-size: 25px; }
			top: 65px; } } }
header.error {
	height: 800px;
	position: fixed;
	@include desktop {
      height: 490px; }
	.header-title {
		position: relative;
		width: 100%;
		left: 0px;
		right: 0px;
		top: 100px;
		text-align: center;
		display: block; }
	@include desktop-xlarge {
		position: relative; }
	@include desktop {
		.logo {
			display: none; }
		.header-title {
			h1 {
				font-size: 50px; }
			top: 150px; }
		background-size: 1366px!important;
		height: 560px; }
	@include mobile {
		height: 330px;
		background-size: 800px!important;
		.header-title {
			top: 100px;
			h1 {
				font-size: 25px; } } } }
header.sticky {
	-webkit-transition: all 0.5s ease;
	-moz-transition: all 0.5s ease;
	transition: all 0.5s ease;
	.header-title {
		display: none; }
	position: fixed !important;
	top: 0px !important;
	z-index: 999999 !important;
	height: 110px;
	padding-top: 20px;
	padding-bottom: 100px;
	background: $site-color2;
	background-image: none!important;
	.logo {
		a {
			background: url(../img/partials/header/logo-alt.svg) no-repeat center; } }
	@include desktop {
		.logo {
			display: none; } } }
