.blog-post {
	background: #f1f1f1;
	#tags_list {
		a {
			&:after {
				content: ','; }
			&:last-child {
				content: ''; } } }
	.post {
		background: #fff;
		color: #505151;
		text-align: left;
		padding-top: 50px;
		padding-bottom: 0px;
		h1 {
			font-size: 34px;
			padding-left: 100px;
			padding-top: 30px;
			padding-bottom: 30px; }
		.post-image {
			text-align: center; }
		.info {
			font-size: 13px;
			padding: 20px 100px;
			// max-width: 705px
			.inline-section {
				max-width: 25%; }
			span {
				font-weight: bold; }
			.bullet {
				padding: 0 5px;
				color: #9fa5b2; } }
		.text {
			padding: 20px 100px;
			ul {
				padding: 20px;
				display: block; }
			ul li {
				padding: 5px 0;
				list-style-type: disc;
				display: list-item; } }
		.share {
			padding: 20px 100px;
			.text {
				padding: 15px; }
			span {
				font-weight: bold;
				padding-right: 20px; }
			i {
				font-size: 22px;
				padding: 15px; } } } }
@include laptop {
	.blog-post {
		.post {
			padding-top: 25px;
			text-align: center;
			h1 {
				padding-left: 50px;
				padding-right: 25px;
				text-align: center; }
			.info {
				padding: 20px;
				text-align: center;
				.inline-section {
					max-width: initial; } }
			.text {
				padding: 20px 50px; }
			.share {
				padding: 10px; } } } }
@include mobile {
	.blog-post {
		.post {
			h1 {
				font-size: 25px;
				line-height: 25px;
				padding: 10px; } } } }
.blog-list {
	padding-bottom: 0px;
	@include mobile {
		padding-top: 25px; }
	.teasers.more {
		padding-top: 0px; }
	.teasers {
		padding-top: 100px;
		padding-bottom: 50px;
		background: #f1f1f1;
		@include desktop-xlarge {
			margin: 0 auto; }
		@include mobile {
			padding-top: 25px;
			padding-bottom: 25px; }
		.teaser {
			max-width: 450px;
			margin-right: 65px;
			margin-bottom: 100px;
			h3 {
				padding: 15px;
				min-height: 85px;
				@include desktop {
					font-size: 22px;
					min-height: 85px; }
				@include laptop {
					min-height: initial; } }
			@include desktop-xlarge {
				max-width: 30%;
				margin: 0 2%;
				margin-bottom: 2%; }
			@include laptop {
				max-width: 450px;
				margin-right: auto;
				margin-left: auto;
				margin-bottom: 50px; }
			.teaser-image:hover img {
			  filter: gray;
			  -webkit-filter: grayscale(1);
			  filter: grayscale(1); }
			.teaser-image img {
			  -webkit-filter: grayscale(0);
			  filter: none; }
			@include desktop {
				font-size: 13px;
				padding: 0 10px;
				.teaser-image:hover img {
				  filter: none;
				  -webkit-filter: none;
				  filter: none; }
				.teaser-image img {
				  -webkit-filter: none;
				  filter: none; } }
			.text {
				background: #fff;
				min-height: 350px;
				@include laptop {
					min-height: initial; }
				.info {
					padding: 10px 5px;
					font-size: 13px;
					color: #bcbcbc;
					span {
						font-size: 14px;
						color: #cacaca; } }
				.description {
						padding: 15px 30px; } }
			.read-more {
				display: inline-block;
				padding: 15px;
				position: absolute;
				bottom: 0px;
				left: 0;
				right: 0;
				margin-left: 25%;
				margin-right: 25%;
				@include laptop {
					position: relative;
					margin-left: 0;
					margin-right: 0; } }
			.read-more.sliding-inverse:after {
				background: #4cb7ef;
				height: 2px; }
			.read-more.sliding-inverse:hover:before {
				background: #4cb7ef;
				height: 2px; } }
		.teaser.last, .teaser:nth-child(3n+3) {
			margin-right: 0;
			@include laptop {
				margin-right: auto; } } } }
