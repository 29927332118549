.contact {
	text-align: left;
	padding-bottom: 0;
	.left {
		float: left; }
	.right {
		float: right; }
	.top {
		padding: 50px 0; }
	.info1 {
		padding: 40px 0;
		line-height: 26px;
		span {
			font-weight: bold; } }
	.info2 {
		padding: 40px 0;
		line-height: 26px;
		padding-right: 100px;
		span {
			font-weight: bold; } }
	.contact-form {
		max-width: 570px;
		margin: 0 auto; }
	.map {
		overflow: hidden;
		clear: both;
		position: relative;
		margin: 25px auto;
		margin-left: 4%;
		@include desktop {
			margin: 25px; }
		#map-canvas2 {
			width: 100%;
			height: 640px;
			@include desktop {
			  height: 440px; } }
		#map-canvas3 {
			width: 100%;
			height: 640px;
			@include desktop {
			  height: 440px; } }
		.map-overlay {
			background: rgba(1, 43, 99, 0.15);
			width: 100%;
			height: 840px;
			position: absolute;
			top: 0;
			z-index: 1;
			pointer-events: none; } }
	.map.rightm {
	    margin-right: 4%;
	    @include desktop {
	       margin-right: 25px; } } }
.gm-style-iw {
	max-width: 320px !important;
	z-index: 99999999!important;
	top: 15px !important;
	left: 0px !important;
	padding: 0px!important;
	margin: 0px!important;
	overflow: visible!important;
	background-color: #fff!important;
	box-shadow: none!important;
	border: none!important;
	border-radius: none!important;
	font-size: 12px!important; }
@include desktop {
	.contact {
		.left {
			float: none; }
		.right {
			float: none; }
		padding-top: 25px;
		.top {
			padding: 40px 0; }
		.info1 {
			padding: 20px 0;
			text-align: center;
			.box {
				padding: 15px 0; } }
		.info2 {
			padding: 15px 0;
			padding-right: 0;
			text-align: center; }
		.contact-form {
			text-align: center; } } }
@include mobile {
	.contact {
		font-size: 13px;
		line-height: 18px;
		padding-top: 0px;
		.top {
			padding: 10px 0; }
		.info1 {
			line-height: 18px;
			padding: 10px 0;
			.box {
				padding: 15px 0; } }
		.info2 {
			line-height: 18px;
			padding: 15px 0; }
		.contact-form {
			padding: 25px 0; } } }
