$font-family1 : 'Roboto', sans-serif;
$site-color1: #252525;
$site-color2: #012b63;
$site-color3: #79c8eb;
$site-color4: #5d5d5d;
$site-color5: #3c4c58;
$site-color6: #a4a5a7;
$site-color7: #ffffff;
$site-color8: #000000;
$site-color9: #49b9e9;
$site-color10: #626d76;
$links-color: #4cb7ef;
$hover-color: #012b63;
$content-width-outer: 1920px;
$content-width: 1560px;
$content-width-inner: 1290px;
$content-width-inner2: 1100px;
$grid-sm: 576px;
$grid-md: 768px;
$grid-lg: 992px;
$grid-desktop-true: 1025px;
$grid-xl: 1200px;
$grid-desktop-large: 1350px;
$grid-desktop-xlarge: 1560px;
