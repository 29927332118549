.general {
	padding-bottom: 0px;
	text-align: left;
	.text {
		ul {
			padding: 20px;
			display: block; }
		ul li {
			padding: 5px 0;
			list-style-type: disc;
			display: list-item; } }
	.area1 {
		padding: 100px 0;
		.text {
			padding-left: 60px;
			padding-top: 0px;
			padding-bottom: 30px; } }
	.area2 {
		padding: 60px 0;
		background: #f9f9f9;
		.text {
			padding: 40px 20px; }
		.text.last {
			padding-left: 60px; } } }
@include desktop {
	.general {
		.area1 {
			padding: 20px 0;
			text-align: center; }
		.area2 {
			padding: 20px 0;
			background: #f9f9f9;
			text-align: center;
			.text {
				padding: 20px 0; } } } }
@include desktop {
	.general {
		.area1 {
			padding: 20px 0;
			.text {
				padding: 20px 0; } }
		.area2 {
			padding: 20px 0;
			background: #f9f9f9;
			padding-bottom: 40px;
			.text.last {
				padding-left: 0px; } } } }
@include mobile {
	.general {
		padding-top: 25px; } }
.general-list {
	.description {
		padding: 65px 0; }
	.list-type1 {
		display: inline-block;
		background: #f6f6f6;
		padding: 65px 0;
		padding-left: 90px;
		width: 1540px;
		@include desktop-xlarge {
			padding-left: 10px; }
		margin-bottom: 100px;
		display: inline-block;
		.image {
			float: right;
			img {
				float: left;
				max-width: none; }
			@include desktop {
				width: 100%;
				img {
					max-width: 100%;
					width: 100%; } } }
		.box {
			a:link, a:visited {
				color: $site-color7; }
			a:hover, a:focus {
				color: $site-color3; }
			h3 {
				font-size: 30px; }
			background: #012b63;
			min-height: 462px;
			color: #fff;
			text-align: right;
			padding: 40px;
			.text {
				padding: 20px 0; }
			.read-more {
				display: inline-block;
				padding: 15px 0; }
			.read-more.sliding-inverse:after {
				background: #fff;
				height: 2px; }
			.read-more.sliding-inverse:hover:before {
				background: #fff;
				height: 2px; } } }
	.list-type2 {
		display: block;
		margin-bottom: 100px;
		width: 1540px;
		.image {
			img {
				position: absolute;
				left: 65px;
				top: 45px;
				z-index: 1;
				@include desktop-xlarge {
					left: 2%; } } }
		.box {
			h3 {
				font-size: 30px; }
			text-align: left;
			min-height: 500px;
			border: solid 1px rgba(233, 233, 233, 0.5);
			padding: 80px;
			max-width: 775px;
			float: left;
			position: relative;
			left: -85px;
			@include desktop-xlarge {
				left: -2%; }
			.text {
				padding: 20px 0; } } } }
@include desktop {
	.general-list {
		.description {
			padding: 35px 0; }
		.list-type1 {
			margin-bottom: 50px;
			padding: 50px;
			.image {
				float: none;
				width: 100%;
				img {
					width: 100%;
					float: none; } }
			.box {
				width: 100%;
				text-align: center;
				min-height: 0px; } }
		.list-type2 {
			margin-bottom: 25px;
			.image {
				width: 100%;
				padding: 0 50px;
				position: relative;
				img {
					position: relative;
					left: 0;
					right: 0;
					top: 0px;
					margin: 0 auto; } }
			h3 {
				padding: 25px 0; }
			.box {
				width: 895px;
				margin: 0px auto;
				text-align: center;
				float: none;
				position: relative;
				bottom: 0px;
				padding: 50px;
				left: 0;
				right: 0;
				max-width: none;
				min-height: 0px;
				margin-top: 50px;
				margin-bottom: 50px;
				.text {
					margin-top: 25px; } } } } }
@include desktop;
.general-list {
		.list-type1 {
			width: 100%; }
		.list-type2 {
			width: 100%;
			h3 {
				position: relative;
				top: 25px; }
			.box {
				.text {
					margin-top: 0px;
					margin-bottom: 0px; } } } }
@include laptop {
	.general-list {
		padding: 25px 0;
		.list-type1 {
			width: 100%; }
		.list-type2 {
			h3 {
				top: 0px; }
			margin-bottom: 0px;
			.box {
				bottom: 25px;
				margin-bottom: 0px;
				margin-top: 0px;
				width: 100%;
				.text {
					margin-top: 15px; } } } } }
@include mobile {
	.general-list {
		font-size: 13px;
		line-height: 18px;
		padding: 25px 0;
		.read-more {
			font-size: 13px;
			line-height: 18px; }
		.description {
			padding: 25px 0; }
		.list-type1 {
			width: 100%;
			padding: 25px;
			margin-bottom: 25px;
			.image {
				float: none;
				margin: 0 auto;
				img {
					float: none; } }
			.box {
				position: relative;
				h3 {
					font-size: 17px;
					padding: 5px 0; }
				.text {
					padding: 10px 0; }
				width: 100%;
				padding: 40px 20px;
				text-align: center;
				min-height: 0px; } }
		.list-type2 {
			margin-bottom: 0px;
			h3 {
				padding: 5px 0; }
			.image {
				padding: 0 50px;
				position: relative;
				img {
					position: relative;
					left: 0;
					right: 0;
					top: 25px;
					margin: 0 auto; } }
			.box {
				position: relative;
				width: 100%;
				bottom: 50px;
				padding: 50px;
				left: 0;
				max-width: none;
				min-height: 0px;
				margin-top: 0px;
				margin-bottom: 0px;
				.text {
					margin-top: 50px; } }
			.read-more {
				display: block;
				text-align: center;
				width: 55%;
				margin: 0 auto; } } } }
